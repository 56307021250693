.App {
  display: contents;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.loader {
  border: 4px solid #cdcdcd; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* input:invalid {
  color: red;
  border-color: red;
} */

/* select:invalid {
  color: red;
  border-color: red;
} */

.center-spinner {
  width: 100%;
  padding: auto;
  text-align: center;
}

@media (min-width: 1025px) {
  .kt-wrapper {
    padding-top: 70px !important;
  }
}

/* ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  opacity: 0.1;
}

::-webkit-scrollbar:hover {
  width: 16px;
  height: 16px;
  opacity: 1;
}

::-webkit-scrollbar-track {
  background: #cdcdcd;
  width: 8px;
  opacity: 0.1;
}

::-webkit-scrollbar-track:hover {
  width: 16px;
  opacity: 1;
}

::-webkit-scrollbar-thumb {
  background: #5d78ff;
  border-radius: 4px;
  opacity: 0.1;
}

::-webkit-scrollbar-thumb:hover {
  background: #3758ff;
  width: 16px;
  height: 16px;
  opacity: 1;
} */

.custom-modal {
  top: 10px;
  width: 40%;
  left: 30%;
  /* margin: 0px 30%; */
  overflow: auto;
  max-height: 80%;
  position: absolute;
  min-width: 300px;
  background-color: #fff;
  display: none;
  padding: 20px;
  border-radius: 4px;
  z-index: 101;
}

.text-align-center {
  width: 100%;
  text-align: center;
}

.custom-modal.show {
  display: block;
}

.modal-close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.5;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  position: absolute;
  overflow: hidden;
  z-index: 99;
  display: none;
}

.backdrop.bg-clear {
  background-color: transparent;
  cursor: default;
}

.backdrop.show {
  display: block;
}

.width-100-pc {
  width: 100%;
}

.kt-badge.kt-badge--info {
  background: #1a73e9;
}

.kt-badge.kt-badge--brand {
  background: #1a73e9;
}

.btn-icon {
  height: 100%;
}

.btn-primary {
  margin: 10px auto;
}

.search-btn {
  padding-top: 2px;
  padding-bottom: 2px;
  width: 100%;
}

.search-bar {
  height: fit-content;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
}

.quick-insights-bar {
  width: 100%;
  padding-left: 25px;
  padding-right: 25px;
  justify-content: space-evenly;
  height: 100px;
}

.quick-insights-card {
  display: inline-block;
  width: 25%;
  height: 80px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 1px 1px 6px 1px rgba(100, 100, 100, 0.2);
}

.quick-insights-card .card-title {
  font-size: 10px;
  margin-bottom: 2px;
}

.quick-insights-card .card-value {
  font-size: 26px;
  font-weight: bold;
}

.container-fluid {
  margin: unset;
  padding: unset;
}

.custom-container {
  border: 1px solid #e2e8ea;
  background-color: #fff;
  width: auto;
  height: auto;
  margin-right: 8px;
  margin-left: 8px;
  /* margin-top: 20px; */
  min-height: 250px;
  padding: unset;
  overflow: auto;
}

.custom-container ul {
  margin-top: 20px;
}

.custom-container ul li {
  margin-left: 5px;
  margin-right: 5px;
  list-style: none;
  display: flex;
  justify-content: space-between;
  cursor: default;
  border-bottom: 1px solid #e2e8ea;
  line-height: 40px;
}

/* .custom-container ul li:nth-child(odd) {
  background-color: #fafafa;
} */

.custom-container table {
  color: #284255;
}

.navbar-top {
  padding-top: 20px;
  text-align: left;
  justify-content: left;
  padding-top: 10px;
  padding-bottom: 0px;
}

.navbar-top a {
  padding-right: 18px;
  padding-left: 18px;
  padding-bottom: 18px;
  margin-top: 10px;
  margin-left: 4px;
  margin-right: 4px;
  color: #595d6e;
  font-weight: 500;
  min-width: 50px;
  text-align: center;
}

.navbar-top button {
  float: right;
  margin-right: 0px;
  margin-bottom: 10px;
  border-radius: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  width: 120px;
  background-color: #d8d3cd;
  border-color: #c7c2bc;
  color: #555;
  font-weight: bold;
}

.navbar-top .top-nav-active {
  border-bottom: 2px solid #1a73e9;
  color: #1a73e9;
}

.empty-table-message {
  display: flex;
  flex: 100;
  text-align: center;
  justify-content: center;
  margin-top: 2%;
  font-size: 18px;
  font-weight: 400;
}

/* .add-client-btn {
  color: #fff !important;
  font-weight: bold;
  width: 100%;
  padding-left: 1px;
  padding-right: 1px;
} */

.add-client-btn {
  width: 120px;
  height: 30px;
  padding: 2px;
  background-color: #d8d3cd;
  border-color: #c7c2bc;
  color: #555;
  font-weight: bold;
  float: right;
  margin-bottom: 10px;
}

/* 
.kt-grid--hor-desktop:hover,
.kt-menu__link:hover,
.kt-menu__item:hover,
.kt-menu__nav:hover,
.kt-menu__item--active:hover{
  background-color: #4B6070;
} */

.margin-unset {
  margin: unset;
}

.justify-evenly {
  justify-content: space-evenly;
}

.display-inline {
  display: inline;
}

.empty-image-container {
  width: 40%;
  margin-left: 30%;
  margin-right: 30%;
  text-align: center;
  padding-top: 30px;
}

.empty-image-container img {
  width: 100%;
}
