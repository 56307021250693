.cursor-pointer {
  cursor: pointer;
}

.user-initial {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #1a73e9;
  text-align: center;
  vertical-align: middle;
  font-weight: 500;
  font-size: 20px;
  padding: 5px;
  color: #fff !important;
  /* color: #004BA8; */
}

.user-row {
  opacity: 0.85;
}

.user-row:hover {
  opacity: 1;
}

.author-search-bar-item {
  padding: 10px;
  width: 250px;
  display: block;
}

#author-table {
  width: 100%;
}

#author-table tr {
  width: 100%;
  height: 48px;
}

#author-table th,
#author-table td {
  min-width: 40px;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.author-actions-icon {
  padding-left: 10px;
  padding-right: 10px;
}

.family-members-list {
  list-style: none;
  padding: unset;
  max-height: 200px;
  overflow-y: auto;
}

#author-policies {
  width: 100%;
  text-align: center;
}

.author-policies-list {
  list-style: none;
  text-align: left;
  padding: unset;
}

.year-container {
  width: 100%;
  text-align: center;
  justify-content: space-around;
  font-size: 1.25rem;
}

.year-container span {
  padding-left: 20px;
  padding-right: 20px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text-non-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.year-container .icon {
  display: inline;
  width: 30%;
  cursor: pointer;
}

.year-container .blurred-year {
  opacity: 0.5;
  cursor: pointer;
}

/* #author-table tbody {
        overflow: auto;
        height: 100px;
    } */
